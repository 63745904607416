import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../../components/layout"
import SingleButton from "../../components/singlebutton"
import { Link } from "gatsby"
import "../../scss/caseStudy.scss"

const CaseStudy = ({ data }) => {
  const templateKey = ""
  const title = "Boosting organic search for Australia's favourite drops."
  const posttitle = "Get Wines Direct"
  const description = ""
  const onesectionheading = "The Challenge"
  const onesectiondescription1 =
    "Get Wines Direct is a pioneering online wine retailer delivering its customers some of the best drops from Australia and around the world. While their brand was strong and their service impeccable, they were struggling to gain traction in growth through organic search, and the site wasn't ranking well for its top-performing brands and products."
  const onesectiondescription2 = ""
  const onesectiontitle = "Experience"

  const twosectiontitle = "Methodology"
  const twosectionheading = "Our solution"
  const twosectiondescription1 = ""
  const twosectiondescription2 =
    "Get Wines Direct was looking for growth. They wanted all of their top-performing brands and products to perform in organic search, and they were looking to see significant increases in rankings, traffic, as well as revenue from their organic search growth."
  const twosectiondescription3 =
    "Through our Organic Search Framework, we were able to increase the trust and authority of the Get Wine Direct domain within the wine category. A critical element of succeeding in this strategy was to obtain mentions across other contextually relevant websites, which in turn, significantly increased organic rankings, traffic and revenue."
  const twosectiondescription4 =
    "Today Get Wines Direct is an authority in organic search with its wine category, so no matter how frequently brands come and go, the new product range remains listed on first page rankings. At latest count, Page One rankings had increased by 7000%."
  const twosectiondescription5 =
    "The authority we were able to build has an evergreen organic presence for the website, allowing Get Wines Direct to enjoy a 50% increase in Organic Traffic and a 40% increase in Organic Revenue. "
  const twosectiondescription6 =
    "Now they can spend more time on what they do best; delivering fantastic wine to grateful Australians."
  const twosectiondescription7 = ""

  const threesectiontitle = "Results"
  const threesectionheading1 = "7000%"
  const threesectiondescription1 = "Increase In Page One Rankings"
  const threesectionheading2 = "50%"
  const threesectiondescription2 = "Increase in Organic Traffic            "
  const threesectionheading3 = "200%"
  const threesectiondescription3 = "Increase in New Customer Acquisition"
  const threesectionheading4 = "40%"
  const threesectiondescription4 = "Increase in Organic Revenue"

  const relatedworkheading1 = "Stratton Finance"
  const relatedworklink1 = "/case-studies/stratton-finance/"
  const relatedworkdescription1 =
    "Stratton Finance are the leading car finance website in Australia, assisting Australians with buying their new car."

  const relatedworkheading2 = "Le Buns"
  const relatedworklink2 = "/case-studies/le-buns/"
  const relatedworkdescription2 =
    "Le Buns is an up and coming Australian swimwear label that puts sustainability and environmental consciousness at the centre of what they do."

  const metatitle = `${posttitle} - Next&Co`
  const metadescription =
    "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success."
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Layout>
        <article className="case-study-article">
          <section id="row-one">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <span className="project-name">{posttitle}</span>
                    <h1>{title}</h1>
                  </div>
                  <div className="col-two">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="outer-container">
            <section id="row-two">
              <div className="col-one">
                <Img fluid={data.featuredimage.childImageSharp.fluid} alt="" />
              </div>
            </section>
          </div>
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h2 className="col-one-heading">{onesectionheading}</h2>
                    <p>{onesectiondescription1}</p>
                    <p>{onesectiondescription2}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id="experience-01" className="outer-container">
            <BackgroundImage
              Tag="section"
              id="row-four"
              fluid={data.onesectionbackgroundimage.childImageSharp.fluid}
              style={{
                backgroundPosition: `center`,
                backgroundSize: `cover`,
              }}
            >
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <span className="number">01</span>
                    <h2 className="col-one-heading">{onesectiontitle}</h2>
                  </div>
                  <div className="col-two">
                    <Img
                      fluid={data.onesectiontopimage.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
                <div className="inner-container">
                  <div className="col-three">
                    <Img
                      fluid={data.onesectionbottomimage.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>

          <div className="outer-container"></div>
          <section id="row-five">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row-one">
                    <span className="number">02</span>
                    <h2>{twosectiontitle}</h2>
                  </div>
                  <div className="row-two">
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectiontopleftimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectiontoprightimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectionbottomleftimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectionbottomrightimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h2>{twosectionheading}</h2>
                    <p>{twosectiondescription2}</p>
                    <p>{twosectiondescription3}</p>
                    <p>{twosectiondescription4}</p>
                    <p>{twosectiondescription5}</p>
                    <p>{twosectiondescription6}</p>
                    <p>{twosectiondescription7}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <BackgroundImage
            Tag="section"
            id="row-seven"
            fluid={data.threesectionbackgroundimage.childImageSharp.fluid}
            style={{
              backgroundPosition: `center`,
              backgroundSize: `cover`,
            }}
          >
            <div className="container-max-width">
              <div className="inner-container">
                <div className="row-one">
                  <div className="content">
                    <h2>{threesectiontitle}</h2>
                    <span className="number">03</span>
                  </div>
                  <div className="data">
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading1)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription1}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading2)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription2}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading3)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription3}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading4)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription4}</p>
                    </div>
                  </div>
                </div>
                <div className="row-two">
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage1.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage2.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage3.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>

          <section id="row-contact-us">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container-contact">
                  <SingleButton buttonText="Get in touch about your project" />
                </div>
              </div>
            </div>
          </section>

          <section id="row-eight">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row-one">
                    <h2>Selected works</h2>
                  </div>
                  <div className="row-two">
                    <div className="col-one">
                      <div className="image-container">
                        <Link to={relatedworklink1} className="no-decoration">
                          <Img
                            fluid={data.relatedworkimage1.childImageSharp.fluid}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link to={relatedworklink1} className="no-decoration">
                          <h3 className="underline-link">
                            {relatedworkheading1}
                          </h3>
                        </Link>
                        <p>{relatedworkdescription1}</p>
                      </div>
                    </div>
                    <div className="col-two">
                      <div className="image-container">
                        <Link to={relatedworklink2} className="">
                          <Img
                            fluid={data.relatedworkimage2.childImageSharp.fluid}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link to={relatedworklink2} className="no-decoration">
                          <h3 className="underline-link">
                            {relatedworkheading2}
                          </h3>
                        </Link>
                        <p>{relatedworkdescription2}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Careers
                 linktitle1={careers.linktitle1}
                 link1={careers.link1}
                 description1={careers.description1}
                 linktitle2={careers.linktitle2}
                 link2={careers.link2}
                 description2={careers.description2}
                 linktitle3={careers.linktitle3}
                 link3={careers.link3}
                 description3={careers.description3} /> */}
        </article>
      </Layout>
    </>
  )
}

export default CaseStudy
// search and replace for "get-wines" to swap out images
export const pageQuery = graphql`
  query {
    featuredimage: file(
      relativePath: { eq: "case-studies/get-wines-00-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectionbackgroundimage: file(
      relativePath: { eq: "case-studies/get-wines-01-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectiontopimage: file(
      relativePath: { eq: "case-studies/get-wines-01-ipad.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectionbottomimage: file(
      relativePath: { eq: "case-studies/get-wines-01-devices.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectiontopleftimage: file(
      relativePath: { eq: "case-studies/get-wines-02-top-left.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectiontoprightimage: file(
      relativePath: { eq: "case-studies/get-wines-02-top-right.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectionbottomleftimage: file(
      relativePath: { eq: "case-studies/get-wines-02-bottom-left.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectionbottomrightimage: file(
      relativePath: { eq: "case-studies/get-wines-02-bottom-right.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionbackgroundimage: file(
      relativePath: { eq: "case-studies/get-wines-03-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage1: file(
      relativePath: { eq: "case-studies/get-wines-03-01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage2: file(
      relativePath: { eq: "case-studies/get-wines-03-02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage3: file(
      relativePath: { eq: "case-studies/get-wines-03-03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    relatedworkimage1: file(
      relativePath: { eq: "case-studies/stratton-finance-00-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    relatedworkimage2: file(
      relativePath: { eq: "case-studies/le-buns-00-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
